.App {
  text-align: center;
}

/* Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5249e ;
  padding: 10px 20px;
  color: #fff;
}

/* Logo Styles */


/* Search Bar Styles */
.search-bar input[type="text"] {
  padding: 5px;
  border: none;
  border-radius: 5px;
  width: 400px; /* Adjust the width as needed */
  height: 30px;
}

.search-bar button {
  padding: 5px 10px;
  background-color: #191D88;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  height: 40px;
  width:  80px;
}

/* Cart Icon Styles */
.cart-icon i {
  font-size: 24px;
  margin-right: 10px;
}

.cart-item-count {
  background-color: #0f120e;
  color: #fff;
  border-radius: 50%;
  padding: 4px 8px;
  font-size: 12px;
  position: relative;
  top: -40px;
  left: -15px;
}

.user-profile  {
  border-radius: 50%;
  background-color: lightsalmon;
  height: 60px;
  width:  60px;
}


/* User Profile Styles */
.user-profile i {
  font-size: 24px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #3300ff;
}

/* Add more styles and media queries as needed for responsiveness */


/* Example CSS for Font Awesome Icons */
.fa {
  font-family: 'Font Awesome 5 Free'; /* Font Awesome font family */
  font-weight: 400; /* Regular font weight */
  font-style: normal; /* Normal font style */
  text-rendering: auto;
}

/* Style specific icons */
.fa-shopping-cart {
  font-size: 24px; /* Adjust the size as needed */
  color: #ff6600; /* Set the icon color */
}

.fa-user-circle {
  font-size: 24px; /* Adjust the size as needed */
  color: #333; /* Set the icon color */
}

/* Hover effect (optional) */
.fa:hover {
  opacity: 0.8; /* Reduce opacity on hover for a visual effect */
}

/* Add more specific styles or animations as needed */

/* CartSidebar.css */

.cart-sidebar {
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 100%;
  background-color: #fff;
  border-left: 1px solid #ccc;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  padding: 20px;
}

.cart-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.cart-item {
  display: flex;
  margin-bottom: 20px;
}

.cart-item-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  margin-right: 10px;
}

.cart-item-details {
  flex-grow: 1;
}

.cart-item-name {
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}

.cart-item-price {
  padding: 10px;
  font-size: 14px;
  color: #777;
}

.cart-item-quantity {
  padding: 10px;
  font-size: 0.9rem;
  color: #777;
}

.cart-item-quantity button {
  background-color: #ff5722;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.cart-item-quantity button:hover {
  background-color: #e64a19;
}

.empty-cart {
  font-size: 16px;
  text-align: center;
  color: #777;
}

.cart-subtotal {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
}

.checkout-button {
  display: block;
  width: 100%;
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px 0;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.checkout-button:hover {
  background-color: #388e3c;
}


/* App.css (or create a separate CSS file and import it) */

/* Style for product cards */
.product-card {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 10px;
  text-align: center;
  background-color: #9cd0db;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 300px;
  height: auto;
}

.product-image {
  max-width: 100%;
  height: 100px;
}

.product-name {
 
  font-size: 1.2rem;
  margin: 10px 0;
}

.product-price {
  
  font-size: 1.1rem;
  font-weight: bold;
  color: #007bff;
}

.quantity-control {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.quantity-control button {
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  width: 30px;
  height: 30px;
}

.quantity-control span {
  margin: 0 10px;
  font-size: 1.1rem;
}

.add-to-cart-button {
  background-color: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 10px 20px;
  margin-top: 10px;
  transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
  background-color: #218838;
}

.product-list{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
}





/* CheckoutForm.css */

.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  
}

.modal-content {
  background-color: #fefefe;
  margin: 5% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 50%;
}

.close {
  color: #aaa;
  float: left;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* CheckoutForm.css */

/* Style for the form */
form {
  display: flex;
  flex-direction: column;
  padding: 20px; /* Padding for the form */
  width: 100%; /* Adjust the width as needed */
  margin: 0 auto; 
}


/* Style for input fields */
input, textarea {
  
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  
}


label{
  font-weight: bold;
}

/* Style for submit button */
button[type="submit"] {
  padding: 10px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
  align-self: center;
}

button[type="submit"]:hover {
  background-color: #0056b3;
}

.category{
  background-color: lightpink;
  display:flex;
  justify-content: space-around;
  font-weight:bold;
  padding: 10px;
 }

 .mobile , .email , .pincode , .address{
  display: flex;
  align-items: center;
  flex-direction: row;
 }

 .address{
  display: flex;
  align-items: flex-start;
 }

 .mobile label {
  padding-right : 75px;
 }

 .email label {
  padding-right : 85px;
 }

 .pincode label {
  padding-right : 65px;
 }

 .address label {
  padding-top: 5px;
  padding-right : 65px;
 }




@media only screen and (max-width: 600px) {
  .header {
    flex-direction: column;
    padding: 10px 0px;
   
  }

  .logo{
    padding-right: 100px;
    padding-left: 50px;
  }

  .search-bar input[type="text"] {
    width: 200px; /* Adjust the width as needed */
    height: 30px;
    margin-top: 10px;
  }

  .cart-icon  {
    padding-top: 10px;
  }
 
  .category{
    flex-direction: column;
   }

   .cart-sidebar {
    bottom: 0px;
    width: 90%;
    height: 90%;
    
  }

  
  .mobile , .email , .pincode , .address {
    flex-direction: column;
    align-items: center;
    justify-content: center;
   }


   form {
    padding: 0px; 
  }

  .modal-content {
    padding: 10px;
    width: 80%;
  }

  .mobile label , .email label , .pincode label , .address  label {
    padding-right : 0px;
    padding-top: 5px;
   }
  


   
  

  
}